@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Quicksand&display=swap');


body {
    font-family: 'Quicksand', sans-serif;
    min-width: 320px !important;
}

.display-picture {
    position: relative;
    z-index: 2;
    max-height: 350px;
    border-radius: 200%;
    left: 6px;
}


.image-container {
    position: relative;
    float: left;
}


.banner {
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    background-color: white;
    overflow: hidden;
}

.wobble {
    position: absolute;
    height: 392px;
    z-index: 1;
    right: 0;
    left: 0;
}

.random1 {
    position: absolute;
    z-index: -1;
    height: 31vh;
    top: 0;
    right: 0;
}

.random2 {
    position: absolute;
    z-index: -1;
    height: 31vh;
    left: 0;
    top: 50%;
}

.random3 {
    position: absolute;
    z-index: -1;
    height: 200px;
    left: 53%;
    top: 73%;
}

.main {
    padding: 40px 250px;
}

.title {
    display: inline-block;
    width: 40%;
    margin: 15px 50px;
}

.name {
    font-size: 2.5em;
}

.subtitle {
    color: gray;
    font-size: 1.1em;
    text-align: justify;
}

.center {
    text-align: center;
}

.topic-container {
    position: relative;
    margin-top: 40px;
}

@media only screen and (max-width : 1366px) {
    .main {
        padding: 40px 100px;
    }

    .wobble {
        height: 364px;
    }

    .display-picture {
        max-height: 324px;
    }
}


@media only screen and (max-width : 1200px) {
    .main {
        padding: 40px 100px;
    }

    .wobble {
        height: 342px;
    }

    .display-picture {
        max-height: 306px;
    }
}

@media only screen and (max-width : 920px) {
    .main {
        padding: 42px 50px;
    }

    .wobble {
        height: 280px;
    }

    .display-picture {
        max-height: 250px;
    }

    .random3 {
        top: 110%;
    }
}

@media only screen and (max-width : 768px) {
    .main {
        padding: 0;
    }

    .wobble {
        height: 280px;
    }

    .display-picture {
        max-height: 250px;
    }

    .title {
        width: 100%;
        margin: 0px;
    }

    .main {
        text-align: center;
    }

    .image-container {
        float: none;
        width: 250px;
        margin: auto;
    }

    .random3 {
        left: 60%;
        bottom: -66%;
        top: initial;
    }

    .random2 {
        bottom: -30%;
        top: initial;
        left: -20px;
    }

    .random1 {
        z-index: 0;
        top: -57px;
        right: 0px;
        height: 23vh;
    }

    .topic-container {
        margin-top: 50px;
    }

    .banner{
        box-shadow: none;
    }

}


@media only screen and (max-width : 460px) {
    .random3 {
        left: 45%;
        bottom: -96%;
        top: initial;
    }

    .random2 {
        bottom: -25%;
        top: initial;
    }

    .random1 {
        z-index: 0;
        top: -57px;
        right: 0px;
        height: 23vh;

    }
}

@media only screen and (max-width : 320px) {
    .random3 {
        left: 40%;
        bottom: -110%;
        top: initial;
    }

    .random2 {
        bottom: -25%;
        top: initial;
    }

    .random1 {
        z-index: 0;
        top: -57px;
        right: 0px;
        height: 23vh;
    }
}