@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Quicksand&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    font-family: 'Quicksand', sans-serif;
    min-width: 320px !important;
}

.display-picture {
    position: relative;
    z-index: 2;
    max-height: 350px;
    border-radius: 200%;
    left: 6px;
}


.image-container {
    position: relative;
    float: left;
}


.banner {
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    background-color: white;
    overflow: hidden;
}

.wobble {
    position: absolute;
    height: 392px;
    z-index: 1;
    right: 0;
    left: 0;
}

.random1 {
    position: absolute;
    z-index: -1;
    height: 31vh;
    top: 0;
    right: 0;
}

.random2 {
    position: absolute;
    z-index: -1;
    height: 31vh;
    left: 0;
    top: 50%;
}

.random3 {
    position: absolute;
    z-index: -1;
    height: 200px;
    left: 53%;
    top: 73%;
}

.main {
    padding: 40px 250px;
}

.title {
    display: inline-block;
    width: 40%;
    margin: 15px 50px;
}

.name {
    font-size: 2.5em;
}

.subtitle {
    color: gray;
    font-size: 1.1em;
    text-align: justify;
}

.center {
    text-align: center;
}

.topic-container {
    position: relative;
    margin-top: 40px;
}

@media only screen and (max-width : 1366px) {
    .main {
        padding: 40px 100px;
    }

    .wobble {
        height: 364px;
    }

    .display-picture {
        max-height: 324px;
    }
}


@media only screen and (max-width : 1200px) {
    .main {
        padding: 40px 100px;
    }

    .wobble {
        height: 342px;
    }

    .display-picture {
        max-height: 306px;
    }
}

@media only screen and (max-width : 920px) {
    .main {
        padding: 42px 50px;
    }

    .wobble {
        height: 280px;
    }

    .display-picture {
        max-height: 250px;
    }

    .random3 {
        top: 110%;
    }
}

@media only screen and (max-width : 768px) {
    .main {
        padding: 0;
    }

    .wobble {
        height: 280px;
    }

    .display-picture {
        max-height: 250px;
    }

    .title {
        width: 100%;
        margin: 0px;
    }

    .main {
        text-align: center;
    }

    .image-container {
        float: none;
        width: 250px;
        margin: auto;
    }

    .random3 {
        left: 60%;
        bottom: -66%;
        top: auto;
        top: initial;
    }

    .random2 {
        bottom: -30%;
        top: auto;
        top: initial;
        left: -20px;
    }

    .random1 {
        z-index: 0;
        top: -57px;
        right: 0px;
        height: 23vh;
    }

    .topic-container {
        margin-top: 50px;
    }

    .banner{
        box-shadow: none;
    }

}


@media only screen and (max-width : 460px) {
    .random3 {
        left: 45%;
        bottom: -96%;
        top: auto;
        top: initial;
    }

    .random2 {
        bottom: -25%;
        top: auto;
        top: initial;
    }

    .random1 {
        z-index: 0;
        top: -57px;
        right: 0px;
        height: 23vh;

    }
}

@media only screen and (max-width : 320px) {
    .random3 {
        left: 40%;
        bottom: -110%;
        top: auto;
        top: initial;
    }

    .random2 {
        bottom: -25%;
        top: auto;
        top: initial;
    }

    .random1 {
        z-index: 0;
        top: -57px;
        right: 0px;
        height: 23vh;
    }
}
.nav-container {
    display: inline-block;
    height: 200px;
    width: 300px;
    margin: 20px;

}

titles-cont {
    position: absolute;
    right: -50px;
}

.titles {
    position: absolute;
}

.nav-title {
    font-size: 4em;
    color: white;
    text-align: left;
}

.titles-blue {
    color: #0e2a47
}

.main-nav {
    position: relative;
    display: inline-block;
    margin: 0px 100px 0px 0px;
}

.explore {
    position: absolute;
    right: -54px;
    bottom: 26px;
    font-size: 1.2em;
    color: #0e2a47;
}

.small-nav {
    font-size: 0.25em;
    position: absolute;
    padding: 10px;
    bottom: 26px;
}

.small-link-name {
    margin: 2px 0px;
}

.explore a {
    color: #0e2a47;
    text-decoration: underline overline;
    text-underline-offset: 5px;

}

.explore a:hover {
    color: white;
    text-underline-offset: 5px;
    background-color: #0e2a47;

}

.small-link-name a {
    text-decoration: none;
    color: white;
}

.small-link-name a:hover {
    color: wheat;
}

@media only screen and (max-width : 460px) {
    .nav-container {
        width: 178px;
        height: 178px;
    }

    .nav-title {
        font-size: 3.75em;
    }
}


@media only screen and (max-width : 320px) {
    .nav-container {
        width: 178px;
        height: 178px;
    }

    .nav-title {
        font-size: 3.75em;
    }
}
.social-icons {
    height: 25px;
    margin: 10px 5px;
}

.social-links {
    display: inline-block;
    position: relative;
    z-index: 1;
}
.Contact {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.qr-image {
    height: 320px;
}

.contact-title {
    font-size: 1.7em;
    padding: 20px 10px 0px 10px;
}

.contact-subtitle {
    font-size: 1em;
    padding: 5px 20px 5px 10px;
    /* flex : 0 0 100%; */
}

.conatct-title-wrap {
    text-align: left;
}

@media only screen and (max-width : 600px) {
    .conatct-title-wrap {
        text-align: center;
    }
}
/* .Personal {} */

.filterbox {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    display: -webkit-inline-flex;
    display: inline-flex;
}


.timeline-wrap {
    max-width: 500px;
    margin: auto;
}


@media only screen and (max-width : 560px) {
    .timeline-wrap {
        width: 400px;
    }
}

@media only screen and (max-width : 460px) {
    .timeline-wrap {
        width: 270px;
    }
}

@media only screen and (max-width : 320px) {
    .timeline-wrap {
        width: 260px;
    }
}
/* .Interests {} */

.interest-container {
    display: inline-block;
}

.interest-image {
    height: 60px;
    margin: 0px 10px;
}
/* .Timeline {} */

.timeline-data {
    border-top: 2px dashed;
    border-color: #0e2a47 !important;
    margin: 0;
    padding: 30px;
    counter-increment: section;
    position: relative;
}



.timeline-data:nth-child(even):before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0px;
    display: block;
    border-left: 4px solid #0e2a47;
    border-top: 4px solid #0e2a47;
    width: 25px;
    height: 25px;
    float: left;
    -webkit-transform: translate(50%, -53%) rotate(-45deg);
            transform: translate(50%, -53%) rotate(-45deg);
}

.timeline-data:nth-child(odd):before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    display: block;
    border-left: 4px solid #0e2a47;
    border-top: 4px solid #0e2a47;
    width: 25px;
    height: 25px;
    float: right;
    -webkit-transform: translate(53%, -50%) rotate(-135deg);
            transform: translate(53%, -50%) rotate(-135deg);
}



.timeline-data:nth-child(even) {
    border-left: 2px dashed;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    margin-right: 30px;
    padding-right: 0;
}

.timeline-data:nth-child(odd) {
    border-right: 2px dashed;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-left: 30px;
    padding-left: 0;
}

.timeline-data:first-child {
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.timeline-data:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.job-title {
    margin-top: 10px;
}

.small-job {
    font-size: .8em;
}

.small-job-date {
    font-size: .7em;
}

.timeline-data a {
    margin-left: 2px;
    color: #0e2a47;
}
.Education {
    display: -webkit-flex;
    display: flex;
    text-align: left;
    /* padding: 0px 2px; */
}

.school-icon {
    height: 60px;
    padding: 5px;
}

.school-image-wrap {
    min-width: 70px;
    margin-left: 10px;
}

.school-details {
    padding: 4px;
}

.small-school {
    font-size: 1em;
}

.small-school-name {
    font-size: .9em;
}

.small-school-date {
    font-size: .8em;
    display: inline-block;
}

.marks {
    float: right;
}

.school-details a {
    margin-left: 2px;
    color: #0e2a47;
}
/* .Projects {} */

.project-container {
    min-width: 300px;
    margin: 0 auto;
    width: 48%;
    display: inline-block;
}

.skill-subname {
    font-size: 1em;
    text-align: left;
}

.project-name {
    font-size: 1.4em;
    padding: 20px;
}

.tag-wrap {
    text-align: left;
}

.intro {
    text-align: justify;
    padding: 0px 10px;
}

.collapsible {
    cursor: pointer;
    padding: 14px 8px 10px 18px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    margin: 15px 5px 5px 10px;
    font: inherit;
}

.active,
.collapsible:hover {
    background-color: rgb(239, 239, 239);
}

.content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.collapsible:after {
    content: "See task and responsiblities"
}

.collapseArrow:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/%3E%3C/svg%3E");
    float: right;
    margin: -3px 6px;
}

.active:after {
    content: "Minimise task and responsiblities";
    /* Unicode character for "minus" sign (-) */
}

.arrowActive:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z'/%3E%3C/svg%3E");
    float: right;
    margin: -3px 6px;
}

.project-btn-wrap {
    text-align: left;
}


@media only screen and (max-width : 600px) {
    .project-container {
        width: 100%;
    }
}
/* .Tag {} */

.tag-container {
    position: relative;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background-color: #0e2a47;
    color: white;
    width: -webkit-max-content;
    width: max-content;
    height: 32px;
    border-radius: 22px;
    padding: 0 0 0 12px;
    line-height: 44px;
    text-align: center;
    cursor: pointer;
    margin: 2px 2px;
}

.tag-container:before {
    content: "";
    position: absolute;
    left: 21px;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    transition: all .33s cubic-bezier(.68, -.55, .265, 1.55);
}

.tag-container .text {
    white-space: nowrap;
    font-size: 14px;
    transition: all .3s cubic-bezier(.68, -.55, .265, 1.55);
    margin: 0px 6px;
}

.tag-container .icon-arrow {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 20px;
    border-radius: 50%;
    background-color: white;
    transition: all .3s cubic-bezier(.68, -.55, .265, 1.55);
}

.tag-container .icon-arrow:after {
    content: "";
    display: inline-block;
    background-color: inherit;
    position: absolute;
    /* left: 100%; */
    width: 25px;
    height: 2px;
    top: 4px;
}

.tag-container:hover {
    -webkit-animation: icon-arrow-off .33s cubic-bezier(0, 0, .2, 1);
            animation: icon-arrow-off .33s cubic-bezier(0, 0, .2, 1);
}

@-webkit-keyframes icon-arrow-off {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    50% {
        -webkit-transform: scale(0.95);
                transform: scale(0.95);
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes icon-arrow-off {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    50% {
        -webkit-transform: scale(0.95);
                transform: scale(0.95);
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.tag-container:hover:before {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}

.tag-container:hover .text {
    -webkit-transform: translateX(21px);
            transform: translateX(21px);
}

.tag-container:hover .icon-arrow {
    -webkit-transform: translateX(30px) scale(.8);
            transform: translateX(30px) scale(.8);
    opacity: 0;
}
.skill-container {
    min-width: 300px;
    margin: 0 auto;
    padding: 0px 10px;
}

.skill-subname {
    font-size: 1em;
    text-align: left;
}

.skill-name {
    font-size: 1.4em;
    padding: 20px;
}

.bar {
    margin: 5px 0 3px;
    background: #ff323240;
    overflow: hidden;
}

.bar>span {
    display: block;
    height: 10px;
    width: var(--my-css-var);
    background-color: #14375c;
    /* background-color: #FF9932; */
    /* border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px; */
}

.animate span {
    animation: progress 2s linear 1;
    -moz-animation: progress 2s linear 1;
    -webkit-animation: progress 2s linear 1;
    -ms-animation: progress 2s linear 1;
    -o-animation: progress 2s linear 1;
}

@-webkit-keyframes progress {
    from {
        width: 0%;
    }

    to {
        width: var(--my-css-var);
    }
}

@keyframes progress {
    from {
        width: 0%;
    }

    to {
        width: var(--my-css-var);
    }
}
.TopicHeader {
    margin-bottom: 40px;
    /* padding: 0px 0px 30px 0px; */
}

.backgroundAmoeba1 {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23bacce1' d='M24.5,-35.7C32.4,-28,40,-21.7,49.9,-11.5C59.8,-1.2,71.9,13,68.8,22.3C65.8,31.6,47.5,36,33.5,47.5C19.6,59,9.8,77.6,-4.4,83.6C-18.5,89.6,-37.1,83.1,-46.5,70.1C-56,57.2,-56.4,37.8,-56.4,22.1C-56.4,6.3,-56,-5.9,-51.1,-15.3C-46.2,-24.6,-36.8,-31.2,-27.5,-38.5C-18.3,-45.8,-9.1,-53.8,-0.4,-53.2C8.3,-52.7,16.6,-43.4,24.5,-35.7Z' transform='translate(100 100)' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.backgroundAmoeba2 {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23bacce1' d='M29.3,-48.3C36,-35.5,38.1,-24.3,39.6,-14.1C41.2,-4,42.2,5,42.7,17.1C43.2,29.2,43.2,44.3,36,52.9C28.8,61.6,14.4,63.8,2.9,59.8C-8.6,55.9,-17.3,45.8,-28.6,38.4C-39.9,31.1,-53.9,26.6,-65,15.6C-76.2,4.6,-84.6,-12.8,-78.1,-23C-71.5,-33.2,-49.9,-36.1,-34.4,-46.1C-18.9,-56,-9.5,-73,0.9,-74.2C11.3,-75.5,22.6,-61.1,29.3,-48.3Z' transform='translate(100 100)' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.backgroundAmoeba3 {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23bacce1' d='M27.8,-31.9C40.3,-29.2,57.7,-26.8,60,-19.5C62.4,-12.2,49.8,-0.1,41.1,9.3C32.4,18.6,27.7,25.1,21.5,30.9C15.3,36.7,7.6,41.8,-3.5,46.6C-14.5,51.3,-29.1,55.7,-35.3,49.9C-41.5,44.1,-39.5,28.1,-38.9,15.9C-38.4,3.7,-39.4,-4.7,-37.3,-12.4C-35.2,-20.1,-30,-27.2,-23.3,-31.8C-16.6,-36.4,-8.3,-38.6,-0.3,-38.2C7.6,-37.7,15.3,-34.7,27.8,-31.9Z' transform='translate(100 100)' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.backgroundAmoeba4 {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23bacce1' d='M23.4,-0.3C29.8,12.3,34.3,32.8,22.8,44.3C11.2,55.7,-16.3,58,-26.1,48.1C-35.8,38.1,-27.7,15.9,-20.3,2.1C-13,-11.7,-6.5,-17.1,1,-17.4C8.5,-17.7,16.9,-13,23.4,-0.3Z' transform='translate(100 100)' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.main-paper {
    position: relative;
    /* padding: 0px 10px; */
    box-shadow: 0 -4px 8px -5px #333;
    background-color: white;
}

.paper-clip-icon,
.paper-clip-icon::after,
.paper-clip-icon::before {
    border-color: #0e2a47 !important;
}

.paper-clip-icon {
    margin-top: -40px;
    margin-left: 10px;
    width: 34px;
    height: 22px;
    border-bottom: none;
    border-left: 6px solid;
    border-right: 6px solid;
    border-top: none;
    position: relative;
    z-index: 5;
}

.paper-clip-icon::before {
    position: absolute;
    content: "";
    top: -30px;
    right: -6px;
    width: 26px;
    height: 50px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border-bottom: none;
    border-left: 6px solid;
    border-right: 6px solid;
    border-top: 6px solid;
}

.paper-clip-icon::after {
    position: absolute;
    content: "";
    bottom: -50px;
    right: 2px;
    width: 18px;
    height: 60px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border-top: none;
    border-left: 6px solid;
    border-right: 6px solid;
    border-bottom: 6px solid;
}

.topic-header-name {
    font-size: 3em;
    padding: 10px;
}

.flex-handle {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-bottom: 20px;
    grid-gap: 15px;
    gap: 15px;
}

.personal-container {
    display: inline-block;
}

.handle-flex-1 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.no-bottom {
    padding: 0;
}

.topic-wrap {
    padding: 0px 0px 30px 0px;
}

.about-website{
    margin-top: 30px;
}

handle-flex-1 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

@media only screen and (max-width : 768px) {
    .paper-clip-icon::before {
        top: -28px;
        right: -6px;
        width: 18px;
        height: 36px;
    }

    .paper-clip-icon::after {
        bottom: -32px;
        right: 2px;
        width: 10px;
        height: 36px;
    }

    .paper-clip-icon {
        width: 26px;
        height: 10px;
    }
}
.Space {}
