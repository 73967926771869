/* .Personal {} */

.filterbox {
    flex: 0 0 100%;
    display: inline-flex;
}


.timeline-wrap {
    max-width: 500px;
    margin: auto;
}


@media only screen and (max-width : 560px) {
    .timeline-wrap {
        width: 400px;
    }
}

@media only screen and (max-width : 460px) {
    .timeline-wrap {
        width: 270px;
    }
}

@media only screen and (max-width : 320px) {
    .timeline-wrap {
        width: 260px;
    }
}