.Education {
    display: flex;
    text-align: left;
    /* padding: 0px 2px; */
}

.school-icon {
    height: 60px;
    padding: 5px;
}

.school-image-wrap {
    min-width: 70px;
    margin-left: 10px;
}

.school-details {
    padding: 4px;
}

.small-school {
    font-size: 1em;
}

.small-school-name {
    font-size: .9em;
}

.small-school-date {
    font-size: .8em;
    display: inline-block;
}

.marks {
    float: right;
}

.school-details a {
    margin-left: 2px;
    color: #0e2a47;
}