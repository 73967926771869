/* .Tag {} */

.tag-container {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background-color: #0e2a47;
    color: white;
    width: max-content;
    height: 32px;
    border-radius: 22px;
    padding: 0 0 0 12px;
    line-height: 44px;
    text-align: center;
    cursor: pointer;
    margin: 2px 2px;
}

.tag-container:before {
    content: "";
    position: absolute;
    left: 21px;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    transform: scale(0);
    opacity: 0;
    transition: all .33s cubic-bezier(.68, -.55, .265, 1.55);
}

.tag-container .text {
    white-space: nowrap;
    font-size: 14px;
    transition: all .3s cubic-bezier(.68, -.55, .265, 1.55);
    margin: 0px 6px;
}

.tag-container .icon-arrow {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 20px;
    border-radius: 50%;
    background-color: white;
    transition: all .3s cubic-bezier(.68, -.55, .265, 1.55);
}

.tag-container .icon-arrow:after {
    content: "";
    display: inline-block;
    background-color: inherit;
    position: absolute;
    /* left: 100%; */
    width: 25px;
    height: 2px;
    top: 4px;
}

.tag-container:hover {
    animation: icon-arrow-off .33s cubic-bezier(0, 0, .2, 1);
}

@keyframes icon-arrow-off {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(1);
    }
}

.tag-container:hover:before {
    transform: scale(1);
    opacity: 1;
}

.tag-container:hover .text {
    transform: translateX(21px);
}

.tag-container:hover .icon-arrow {
    transform: translateX(30px) scale(.8);
    opacity: 0;
}