/* .Timeline {} */

.timeline-data {
    border-top: 2px dashed;
    border-color: #0e2a47 !important;
    margin: 0;
    padding: 30px;
    counter-increment: section;
    position: relative;
}



.timeline-data:nth-child(even):before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0px;
    display: block;
    border-left: 4px solid #0e2a47;
    border-top: 4px solid #0e2a47;
    width: 25px;
    height: 25px;
    float: left;
    transform: translate(50%, -53%) rotate(-45deg);
}

.timeline-data:nth-child(odd):before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    display: block;
    border-left: 4px solid #0e2a47;
    border-top: 4px solid #0e2a47;
    width: 25px;
    height: 25px;
    float: right;
    transform: translate(53%, -50%) rotate(-135deg);
}



.timeline-data:nth-child(even) {
    border-left: 2px dashed;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    margin-right: 30px;
    padding-right: 0;
}

.timeline-data:nth-child(odd) {
    border-right: 2px dashed;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-left: 30px;
    padding-left: 0;
}

.timeline-data:first-child {
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.timeline-data:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.job-title {
    margin-top: 10px;
}

.small-job {
    font-size: .8em;
}

.small-job-date {
    font-size: .7em;
}

.timeline-data a {
    margin-left: 2px;
    color: #0e2a47;
}