.TopicHeader {
    margin-bottom: 40px;
    /* padding: 0px 0px 30px 0px; */
}

.backgroundAmoeba1 {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23bacce1' d='M24.5,-35.7C32.4,-28,40,-21.7,49.9,-11.5C59.8,-1.2,71.9,13,68.8,22.3C65.8,31.6,47.5,36,33.5,47.5C19.6,59,9.8,77.6,-4.4,83.6C-18.5,89.6,-37.1,83.1,-46.5,70.1C-56,57.2,-56.4,37.8,-56.4,22.1C-56.4,6.3,-56,-5.9,-51.1,-15.3C-46.2,-24.6,-36.8,-31.2,-27.5,-38.5C-18.3,-45.8,-9.1,-53.8,-0.4,-53.2C8.3,-52.7,16.6,-43.4,24.5,-35.7Z' transform='translate(100 100)' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.backgroundAmoeba2 {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23bacce1' d='M29.3,-48.3C36,-35.5,38.1,-24.3,39.6,-14.1C41.2,-4,42.2,5,42.7,17.1C43.2,29.2,43.2,44.3,36,52.9C28.8,61.6,14.4,63.8,2.9,59.8C-8.6,55.9,-17.3,45.8,-28.6,38.4C-39.9,31.1,-53.9,26.6,-65,15.6C-76.2,4.6,-84.6,-12.8,-78.1,-23C-71.5,-33.2,-49.9,-36.1,-34.4,-46.1C-18.9,-56,-9.5,-73,0.9,-74.2C11.3,-75.5,22.6,-61.1,29.3,-48.3Z' transform='translate(100 100)' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.backgroundAmoeba3 {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23bacce1' d='M27.8,-31.9C40.3,-29.2,57.7,-26.8,60,-19.5C62.4,-12.2,49.8,-0.1,41.1,9.3C32.4,18.6,27.7,25.1,21.5,30.9C15.3,36.7,7.6,41.8,-3.5,46.6C-14.5,51.3,-29.1,55.7,-35.3,49.9C-41.5,44.1,-39.5,28.1,-38.9,15.9C-38.4,3.7,-39.4,-4.7,-37.3,-12.4C-35.2,-20.1,-30,-27.2,-23.3,-31.8C-16.6,-36.4,-8.3,-38.6,-0.3,-38.2C7.6,-37.7,15.3,-34.7,27.8,-31.9Z' transform='translate(100 100)' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.backgroundAmoeba4 {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23bacce1' d='M23.4,-0.3C29.8,12.3,34.3,32.8,22.8,44.3C11.2,55.7,-16.3,58,-26.1,48.1C-35.8,38.1,-27.7,15.9,-20.3,2.1C-13,-11.7,-6.5,-17.1,1,-17.4C8.5,-17.7,16.9,-13,23.4,-0.3Z' transform='translate(100 100)' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.main-paper {
    position: relative;
    /* padding: 0px 10px; */
    box-shadow: 0 -4px 8px -5px #333;
    background-color: white;
}

.paper-clip-icon,
.paper-clip-icon::after,
.paper-clip-icon::before {
    border-color: #0e2a47 !important;
}

.paper-clip-icon {
    margin-top: -40px;
    margin-left: 10px;
    width: 34px;
    height: 22px;
    border-bottom: none;
    border-left: 6px solid;
    border-right: 6px solid;
    border-top: none;
    position: relative;
    z-index: 5;
}

.paper-clip-icon::before {
    position: absolute;
    content: "";
    top: -30px;
    right: -6px;
    width: 26px;
    height: 50px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border-bottom: none;
    border-left: 6px solid;
    border-right: 6px solid;
    border-top: 6px solid;
}

.paper-clip-icon::after {
    position: absolute;
    content: "";
    bottom: -50px;
    right: 2px;
    width: 18px;
    height: 60px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border-top: none;
    border-left: 6px solid;
    border-right: 6px solid;
    border-bottom: 6px solid;
}

.topic-header-name {
    font-size: 3em;
    padding: 10px;
}

.flex-handle {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap: 15px;
}

.personal-container {
    display: inline-block;
}

.handle-flex-1 {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.no-bottom {
    padding: 0;
}

.topic-wrap {
    padding: 0px 0px 30px 0px;
}

.about-website{
    margin-top: 30px;
}

handle-flex-1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

@media only screen and (max-width : 768px) {
    .paper-clip-icon::before {
        top: -28px;
        right: -6px;
        width: 18px;
        height: 36px;
    }

    .paper-clip-icon::after {
        bottom: -32px;
        right: 2px;
        width: 10px;
        height: 36px;
    }

    .paper-clip-icon {
        width: 26px;
        height: 10px;
    }
}