.Contact {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.qr-image {
    height: 320px;
}

.contact-title {
    font-size: 1.7em;
    padding: 20px 10px 0px 10px;
}

.contact-subtitle {
    font-size: 1em;
    padding: 5px 20px 5px 10px;
    /* flex : 0 0 100%; */
}

.conatct-title-wrap {
    text-align: left;
}

@media only screen and (max-width : 600px) {
    .conatct-title-wrap {
        text-align: center;
    }
}