/* .Projects {} */

.project-container {
    min-width: 300px;
    margin: 0 auto;
    width: 48%;
    display: inline-block;
}

.skill-subname {
    font-size: 1em;
    text-align: left;
}

.project-name {
    font-size: 1.4em;
    padding: 20px;
}

.tag-wrap {
    text-align: left;
}

.intro {
    text-align: justify;
    padding: 0px 10px;
}

.collapsible {
    cursor: pointer;
    padding: 14px 8px 10px 18px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    margin: 15px 5px 5px 10px;
    font: inherit;
}

.active,
.collapsible:hover {
    background-color: rgb(239, 239, 239);
}

.content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.collapsible:after {
    content: "See task and responsiblities"
}

.collapseArrow:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/%3E%3C/svg%3E");
    float: right;
    margin: -3px 6px;
}

.active:after {
    content: "Minimise task and responsiblities";
    /* Unicode character for "minus" sign (-) */
}

.arrowActive:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z'/%3E%3C/svg%3E");
    float: right;
    margin: -3px 6px;
}

.project-btn-wrap {
    text-align: left;
}


@media only screen and (max-width : 600px) {
    .project-container {
        width: 100%;
    }
}