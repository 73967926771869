.skill-container {
    min-width: 300px;
    margin: 0 auto;
    padding: 0px 10px;
}

.skill-subname {
    font-size: 1em;
    text-align: left;
}

.skill-name {
    font-size: 1.4em;
    padding: 20px;
}

.bar {
    margin: 5px 0 3px;
    background: #ff323240;
    overflow: hidden;
}

.bar>span {
    display: block;
    height: 10px;
    width: var(--my-css-var);
    background-color: #14375c;
    /* background-color: #FF9932; */
    /* border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px; */
}

.animate span {
    animation: progress 2s linear 1;
    -moz-animation: progress 2s linear 1;
    -webkit-animation: progress 2s linear 1;
    -ms-animation: progress 2s linear 1;
    -o-animation: progress 2s linear 1;
}

@-webkit-keyframes progress {
    from {
        width: 0%;
    }

    to {
        width: var(--my-css-var);
    }
}

@-moz-keyframes progress {
    from {
        width: 0%;
    }

    to {
        width: var(--my-css-var);
    }
}

@-ms-keyframes progress {
    from {
        width: 0%;
    }

    to {
        width: var(--my-css-var);
    }
}

@-o-keyframes progress {
    from {
        width: 0%;
    }

    to {
        width: var(--my-css-var);
    }
}

@keyframes progress {
    from {
        width: 0%;
    }

    to {
        width: var(--my-css-var);
    }
}